import {
  nonames,
  nameRegExp,
  llcNameRegExp,
  pscNameRegExp,
  partnershipNameRegExp,
} from "../../../variables/Variables";

export const validateFullName = (event) => {
  const inputElement = document.getElementById(event.target.id);
  const errorElement = inputElement.nextSibling;

  if (event.target.value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The Full name can't be empty";
    errorElement.style.display = "block";
  } else if (!/^[ a-zA-Z\\&-]+$/.test(event.target.value)) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The Full name should contain only letters and & \\ - symbols";
    errorElement.style.display = "block";
  } else if (event.target.value.toString().toLowerCase().includes("null")) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = 'The Full name can\'t contain "Null" name.';
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateFirstName = (event) => {
  const inputElement = document.getElementById(event.target.id);
  const errorElement = inputElement.nextSibling;

  if (event.target.value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The First name can't be empty";
    errorElement.style.display = "block";
  } else if (!/^[ a-zA-Z\\&-]+$/.test(event.target.value)) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The First name should contain only letters and & \\ - symbols";
    errorElement.style.display = "block";
  } else if (event.target.value.toString().toLowerCase().includes("null")) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = 'The First name can\'t contain "Null" name.';
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateMiddleName = (event) => {
  const inputElement = document.getElementById(event.target.id);
  const errorElement = inputElement.nextSibling;

  if (event.target.value.trim().length !== 0) {
    if (!/^[ a-zA-Z\\&-]+$/.test(event.target.value)) {
      inputElement.classList.add("is-invalid");
      errorElement.innerHTML = "The Middle name should contain only letters and & \\ - symbols";
      errorElement.style.display = "block";
    } else {
      errorElement.style.display = "none";
      inputElement.classList.remove("is-invalid");
    }
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateLastName = (event) => {
  const inputElement = document.getElementById(event.target.id);
  const errorElement = inputElement.nextSibling;

  if (event.target.value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The Last name can't be empty";
    errorElement.style.display = "block";
  } else if (!/^[ a-zA-Z\\&-]+$/.test(event.target.value)) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The Last name should contain only letters and & \\ - symbols";
    errorElement.style.display = "block";
  } else if (event.target.value.toString().toLowerCase().includes("null")) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = 'The Last name can\'t contain "Null" name.';
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateDBAName = (event) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length !== 0) {
    if (!/^[ a-zA-Z0-9&-]+$/.test(value)) {
      inputElement.classList.add("is-invalid");
      errorElement.innerHTML = "The only punctuation and special characters allowed are hyphen (-) and ampersand (&).";
      errorElement.style.display = "block";
    } else if (
      !nameRegExp.test(value.trim()) ||
      nonames.includes(value.trim().toLowerCase())
    ) {
      inputElement.classList.add("is-invalid");
      errorElement.innerHTML =
        "The trade name may not contain an ending such as 'LLC', 'LC', 'PLLC', 'PA', 'Corp', or 'Inc'.";
      errorElement.style.display = "block";
    } else {
      errorElement.style.display = "none";
      inputElement.classList.remove("is-invalid");
    }
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateLegalName = (event) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The legal name can't be empty";
    errorElement.style.display = "block";
  } else if (!/^[ a-zA-Z0-9&-]+$/.test(value)) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The only punctuation and special characters allowed are hyphen (-) and ampersand (&).";
    errorElement.style.display = "block";
  } else if (!nameRegExp.test(value.trim()) || nonames.includes(value.trim().toLowerCase())) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML =
      "Name may not contain an ending such as 'LLC', 'LC', 'PLLC', 'PA', 'Corp', or 'Inc'.";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateLLCLegalName = (event) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The legal name can't be empty";
    errorElement.style.display = "block";
  } else if (!/^[ a-zA-Z0-9&-]+$/.test(value)) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The only punctuation and special characters allowed are hyphen (-) and ampersand (&).";
    errorElement.style.display = "block";
  } else if (!llcNameRegExp.test(value.trim()) || ["corp", "inc", "pa"].includes(value.trim().toLowerCase())) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML =
      "The legal name may not contain any of the following endings: Corp, Inc, PA.";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validatePSCLegalName = (event) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The legal name can't be empty";
    errorElement.style.display = "block";
  } else if (!/^[ a-zA-Z0-9&-]+$/.test(value)) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The only punctuation and special characters allowed are hyphen (-) and ampersand (&).";
    errorElement.style.display = "block";
  } else if (!pscNameRegExp.test(value.trim()) || ["llc", "pllc", "lc"].includes(value.trim().toLowerCase())) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML =
      "The legal name may not contain any of the following endings: LLC, PLLC, LC.";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validatePartnershipLegalName = (event) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The legal name can't be empty";
    errorElement.style.display = "block";
  } else if (!/^[ a-zA-Z0-9&-]+$/.test(value)) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The only punctuation and special characters allowed are hyphen (-) and ampersand (&).";
    errorElement.style.display = "block";
  } else if (!partnershipNameRegExp.test(value.trim()) || ["corp", "llc", "pllc", "lc", "inc"].includes(value.trim().toLowerCase())) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML =
      "The legal name may not contain any of the following endings: Corp, LLC, PLLC, LC, Inc.";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateAddress1 = (event, { isCorpAddress }) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The Address field should not be empty";
    errorElement.style.display = "block";
  } else if (!/^[ a-zA-Z0-9\\/-]+$/.test(value)) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The Address field should contain only letters, numbers, dash or slash";
    errorElement.style.display = "block";
  } else if (
    isCorpAddress &&
    (!/(?!.*(?:[pP][oO] [bB][oO][xX]))^[ a-zA-Z0-9\\/-]+$/.test(value) ||
      !/(?!.*(?:[pP][oO][bB][oO][xX]))^[ a-zA-Z0-9\\/-]+$/.test(value) ||
      !/(?!.*(?:[pP][oO][sS][tT] [oO][fF][fF][iI][cC][eE] [bB][oO][xX]))^[ a-zA-Z0-9\\/-]+$/.test(value) ||
      /\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)\b/i.test(value))
  ) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "PO Boxes are not acceptable.";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateAddress2 = (event, { isCorpAddress }) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length !== 0) {
    if (!/^[ a-zA-Z0-9\\/-]+$/.test(value)) {
      inputElement.classList.add("is-invalid");
      errorElement.innerHTML = "The Address field should contain only letters, numbers, dash or slash";
      errorElement.style.display = "block";
    } else if (
      isCorpAddress &&
      (!/(?!.*(?:[pP][oO] [bB][oO][xX]))^[ a-zA-Z0-9\\/-]+$/.test(value) ||
        !/(?!.*(?:[pP][oO][bB][oO][xX]))^[ a-zA-Z0-9\\/-]+$/.test(value) ||
        !/(?!.*(?:[pP][oO][sS][tT] [oO][fF][fF][iI][cC][eE] [bB][oO][xX]))^[ a-zA-Z0-9\\/-]+$/.test(value) ||
        /\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)\b/i.test(value))
    ) {
      inputElement.classList.add("is-invalid");
      errorElement.innerHTML = "PO Boxes are not acceptable.";
      errorElement.style.display = "block";
    } else {
      errorElement.style.display = "none";
      inputElement.classList.remove("is-invalid");
    }
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateCity = (event) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The City field should not be empty";
    errorElement.style.display = "block";
  } else if (!/^[ a-zA-Z/-]+$/.test(value)) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The City field should contain only letters";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateCounty = (event, { required }) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (required) {
    if (value.trim().length === 0) {
      inputElement.classList.add("is-invalid");
      errorElement.innerHTML = "The County field should not be empty";
      errorElement.style.display = "block";
    } else if (!/^[ a-zA-Z&-]+$/.test(value)) {
      inputElement.classList.add("is-invalid");
      errorElement.innerHTML = "The County field should contain only letters, & and dash symbols";
      errorElement.style.display = "block";
    } else {
      errorElement.style.display = "none";
      inputElement.classList.remove("is-invalid");
    }
  }

  if (!required) {
    if (value.trim().length !== 0) {
      if (!/^[ a-zA-Z&-]+$/.test(value)) {
        inputElement.classList.add("is-invalid");
        errorElement.innerHTML = "The County field should contain only letters, & and dash symbols";
        errorElement.style.display = "block";
      } else {
        errorElement.style.display = "none";
        inputElement.classList.remove("is-invalid");
      }
    } else {
      errorElement.style.display = "none";
      inputElement.classList.remove("is-invalid");
    }
  }
};

export const validateYear = (event) => {
  let inputElement = document.getElementById(event.target.id);
  let errorElement = inputElement.nextSibling;
  let now = new Date();
  if (now.getFullYear() - event.target.value >= 25) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "The year cannot be more than 1 year in the future or more than 25 years in the past.";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateAltCompanyName = (event, { matchValue }) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "Alternate Company Name field should not be empty";
    errorElement.style.display = "block";
  } else if (value.trim().toLowerCase() === "llc") {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "Alternate Company Name should not be LLC";
    errorElement.style.display = "block";
  } else if (
    value.trim().toLowerCase() === matchValue.trim().toLowerCase() ||
    value.trim().toLowerCase() ===
      matchValue.toLowerCase().substring(0, matchValue.toLowerCase().indexOf("llc")).trim() ||
    value.toLowerCase().substring(0, value.toLowerCase().indexOf("llc")).trim() ===
      matchValue.toLowerCase().trim()
  ) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "Your alternate name cannot be the same as your primary LLC name";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateTradeName = (event) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "Limited Liability Company Name field should not be empty";
    errorElement.style.display = "block";
  } else if (value.trim().toLowerCase() === "llc") {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "Limited Liability Company Name should not be LLC";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};

export const validateDriverLicenceOrID = (event) => {
  const { id, value } = event.target;
  const inputElement = document.getElementById(id);
  const errorElement = inputElement.nextSibling;

  if (value.trim().length === 0) {
    inputElement.classList.add("is-invalid");
    errorElement.innerHTML = "Please provide Driver's License or ID Number";
    errorElement.style.display = "block";
  } else {
    errorElement.style.display = "none";
    inputElement.classList.remove("is-invalid");
  }
};
